import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { ElMessage } from 'element-plus';
export default {
  components: {},
  data: function data() {
    return {
      userInfo: {
        givenName: '',
        phoneNumber: ''
      },
      loading: false,
      id: null,
      viewData: {
        category: {},
        thumbnail: {}
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.id = this.$route.query.id;
    console.log(this.$route.query.id); // 设置userinfo

    var userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

    if (userInfo && userInfo !== 'null') {
      this.userInfo = userInfo;
    } // 请求详情


    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id);
    }
  },
  methods: {
    getDetail: function getDetail(id) {
      var _this = this;

      this.loading = true;
      this.$axios.get('/api/article/detail-guest', {
        id: id
      }).then(function (res) {
        _this.loading = false;

        if (res.code === 0) {
          _this.viewData = res.data;
        } else {
          ElMessage({
            message: res.message || '操作失败',
            type: 'warning'
          });
        }
      });
    },
    goHome: function goHome() {
      debugger;
      this.$router.push('home');
    },
    // 退出登录
    logout: function logout() {
      window.localStorage.clear();
      this.$router.replace('home');
    }
  }
};