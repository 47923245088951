import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.map.js";
import mock from "./mock.json";
import { ElMessage } from "element-plus";
export default {
  components: {},
  data: function data() {
    return {
      activeFlag: true,
      loading: false,
      loginFlag: false,
      userInfo: null,
      mockData: mock,
      swiperArr: [],
      menuIndex: 0,
      menuTab: ["首页", "产品目录", "行业方案", "新闻活动", "联系我们"],
      productIndex: 0,
      productDeatil: mock.productDeatil,
      loginVisible: false,
      loginParams: {
        userName: "",
        password: "",
        autoFlag: true
      },
      registerVisible: false,
      viewvisible: false,
      viewData: {},
      // 试用详情
      registerParams: {
        organizationName: "",
        organizationIdentifier: "",
        userName: "",
        password: "",
        confirmPassword: "",
        givenName: "",
        phoneNumber: ""
      },
      orderVisible: false,
      orderParams: {
        productId: "",
        productName: "",
        effectiveTime: "",
        expiryTime: "",
        data: []
      },
      articleList: [],
      appId: mock.productDeatil[0].gid,
      appName: mock.productDeatil[0].name,
      tryConsultVisible: false,
      tryConsultParams: {
        name: "",
        phoneNumber: "",
        description: "",
        category: 1,
        AppId: ""
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;

    debugger;
    var isLoginVisible = Boolean(window.localStorage.getItem("IsLoginVisible"));

    if (isLoginVisible) {
      this.openLogin();
      window.localStorage.clear("IsLoginVisible");
    } // 判断是否有userInfo，即为登录状态


    if (!window.localStorage.getItem("userInfo")) {
      this.loginFlag = false;
      this.userInfo = null;
    } else {
      this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
      this.loginFlag = true;
    }

    this.$axios.get("/api/article/paged-list-guest?PlanformType=1&Index=1&Size=3").then(function (res) {
      _this.loading = false;

      if (res.code === 0) {
        _this.articleList = res.data.list;
      } else {
        ElMessage({
          message: res.message || "操作失败",
          type: "warning"
        });
      }
    }); // 产品分类轮播数据处理

    this.swiperArr = this.spliceArr(this.mockData.product, 8);
  },
  methods: {
    handleOrder: function handleOrder(data) {
      this.appId = data.gid; // ElMessage({
      //   message: "请联系右下角客服资讯热线",
      //   type: "success",
      // });

      this.orderVisible = true;
    },
    menuTabClick: function menuTabClick(i) {
      this.menuIndex = i;
      this.goScroll("slider" + (i + 1));
    },
    labelNum: function labelNum(item) {
      var num = 0;

      if (item.school) {
        num++;
      }

      if (item.area) {
        num++;
      }

      if (item.city) {
        num++;
      }

      return "lab" + num;
    },
    productTabClick: function productTabClick(i, item) {
      var that = this;
      this.activeFlag = false;
      this.productIndex = item.id; //this.appId = item.id;

      this.appName = item.name;
      this.$nextTick(function () {
        setTimeout(function () {
          that.activeFlag = true;
        }, 500);
      });
    },
    getUrl: function getUrl(item) {
      return require("@/" + item);
    },
    // 页面滚动
    goScroll: function goScroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    },
    openLogin: function openLogin() {
      this.loginVisible = true;
    },
    loginSubmit: function loginSubmit(params) {
      var _this2 = this;

      if (!this.loginParams.userName) {
        ElMessage({
          message: "请输入用户名",
          type: "warning"
        });
        return;
      }

      if (!this.loginParams.password) {
        ElMessage({
          message: "请输入密码",
          type: "warning"
        });
        return;
      }

      this.loading = true;
      this.$axios.post("/api/authenticate", this.loginParams).then(function (res) {
        if (res.code === 0) {
          // 存储token信息
          window.localStorage.setItem("tokenObj", JSON.stringify(res.data));

          _this2.getUserInfo();
        } else {
          _this2.loading = false;
          ElMessage({
            message: res.message || "操作失败",
            type: "warning"
          });
        }
      }); // axios.post('/api/authenticate', this.loginParams).then(res => {
      //   if (res.code === 0) {
      //     // 存储token信息
      //     window.localStorage.setItem('tokenObj', JSON.stringify(res.data))
      //     this.getUserInfo()
      //   } else {
      //     this.loading = false
      //     ElMessage({
      //       message: res.message || '操作失败',
      //       type: 'warning'
      //     })
      //   }
      // })

      this.loginVisible = false;
    },
    // 获取登录信息
    getUserInfo: function getUserInfo() {
      var _this3 = this;

      this.$axios.get("/api/user/current").then(function (res) {
        _this3.loading = false;

        if (res.code === 0) {
          // 存储token信息
          window.localStorage.setItem("userInfo", JSON.stringify(res.data));
          ElMessage({
            message: res.message || "登录成功",
            type: "success"
          });
          _this3.loginVisible = false;
          window.location.reload();
        } else {
          ElMessage({
            message: res.message || "操作失败",
            type: "warning"
          });
        }
      });
    },
    openRegister: function openRegister() {
      this.loginVisible = false;
      this.registerVisible = true;
    },
    registerSubmit: function registerSubmit() {
      var _this4 = this;

      if (!this.registerParams.organizationName) {
        ElMessage({
          message: "请输入组织机构名称",
          type: "warning"
        });
        return;
      }

      if (!this.registerParams.organizationIdentifier) {
        ElMessage({
          message: "请输入组织机构代码",
          type: "warning"
        });
        return;
      } else {
        var reg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/; //^(1|5|9|Y)(1|2|3|9)([0-9]{6})(([0-9]|[^IOZSVa-z]){9})([0-9]|[^IOZSVa-z])$/;

        if (!reg.test(this.registerParams.organizationIdentifier)) {
          ElMessage({
            message: "请输入正确格式的组织机构代码",
            type: "warning"
          });
          return;
        }
      }

      if (!this.registerParams.userName) {
        ElMessage({
          message: "请输入用户名",
          type: "warning"
        });
        return;
      }

      if (!this.registerParams.password) {
        ElMessage({
          message: "请输入密码",
          type: "warning"
        });
        return;
      }

      if (!this.registerParams.confirmPassword) {
        ElMessage({
          message: "请输入确认密码",
          type: "warning"
        });
        return;
      }

      if (!this.registerParams.givenName) {
        ElMessage({
          message: "请输入姓名",
          type: "warning"
        });
        return;
      }

      if (!this.registerParams.phoneNumber) {
        ElMessage({
          message: "请输入手机号",
          type: "warning"
        });
        return;
      }

      var str = /^[1][3-9]{1}[0-9]{9}$/;

      if (str.test(this.registerParams.phoneNumber) == false) {
        ElMessage({
          message: "请输入正确的手机号",
          type: "warning"
        });
        return;
      }

      this.$axios.post("/api/tenant/register", this.registerParams).then(function (res) {
        if (res.code === 0) {
          ElMessage({
            message: res.message || "注册成功",
            type: "success"
          });
          _this4.registerVisible = false;
          _this4.loginVisible = true;
        } else {
          ElMessage({
            message: res.message || "操作失败",
            type: "warning"
          });
        }
      });
    },
    // 咨询
    customerticket: function customerticket() {
      var _this5 = this;

      var tokenObj = JSON.parse(window.localStorage.getItem("tokenObj"));

      if (!document.querySelector("#name").value) {
        ElMessage({
          message: "请输入联系人姓名",
          type: "warning"
        });
        return;
      }

      if (!document.querySelector("#phoneNumber").value) {
        ElMessage({
          message: "请输入联系人手机号",
          type: "warning"
        });
        return;
      }

      if (!/^1[3456789]\d{9}$/.test(document.querySelector("#phoneNumber").value)) {
        ElMessage({
          message: "手机号码输入有误，请重新填写",
          type: "warning"
        });
        return;
      }

      if (!document.querySelector("#description").value) {
        ElMessage({
          message: "请输入咨询内容",
          type: "warning"
        });
        return;
      }

      this.$axios.post("/api/customerticket", {
        description: document.querySelector("#description").value,
        category: 1,
        name: document.querySelector("#name").value,
        phoneNumber: document.querySelector("#phoneNumber").value
      }).then(function (res) {
        _this5.loading = false;

        if (res.code === 0) {
          ElMessage({
            message: res.message || "提交成功请耐心等待...",
            type: "success"
          });
          document.querySelector("#description").value = "";
          document.querySelector("#name").value = "";
          document.querySelector("#phoneNumber").value = "";
        } else {
          ElMessage({
            message: res.message || "操作失败",
            type: "warning"
          });
        }
      });
    },
    // 下单
    handelOrder: function handelOrder() {
      var _this6 = this;

      if (this.orderParams.data.length < 2) {
        ElMessage({
          message: "请选择时间",
          type: "warning"
        });
        return;
      }

      this.$axios.post("/api/order", {
        productId: this.appId,
        productName: this.appName,
        effectiveTime: this.orderParams.data[0],
        expiryTime: this.orderParams.data[1]
      }).then(function (res) {
        _this6.loading = false;

        if (res.code === 0) {
          ElMessage({
            message: res.message || "订购成功",
            type: "success"
          });
          _this6.orderVisible = false;
        } else {
          ElMessage({
            message: res.message || "操作失败",
            type: "warning"
          });
        }
      });
    },
    // 预览图片
    viewImg: function viewImg(item) {
      if (item) {
        window.open(this.getUrl(item));
      }
    },
    // 退出登录
    logout: function logout() {
      window.localStorage.clear();
      window.location.reload();
    },
    // 我的
    goMy: function goMy() {
      this.$router.push("my");
    },
    // 行业方案
    goIndustry: function goIndustry() {
      var routeUrl = this.$router.resolve("industryList");
      window.open(routeUrl.href, "_blank");
    },
    // 行业方案详情
    goIndustryDetail: function goIndustryDetail(id) {
      var routeUrl = this.$router.resolve({
        path: "industryDetail",
        query: {
          id: id
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    //新闻资讯
    goNewsDetail: function goNewsDetail(type, title, content, time) {
      var routeUrl = this.$router.resolve({
        path: "newsDetail",
        query: {
          type: type,
          title: title,
          content: content,
          time: time
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    handelTryConsult: function handelTryConsult() {
      var _this7 = this;

      if (!this.tryConsultParams.name) {
        ElMessage({
          message: "请输入联系人姓名",
          type: "warning"
        });
        return;
      }

      if (!this.tryConsultParams.phoneNumber) {
        ElMessage({
          message: "请输入联系人手机号",
          type: "warning"
        });
        return;
      }

      if (!/^1[3456789]\d{9}$/.test(this.tryConsultParams.phoneNumber)) {
        ElMessage({
          message: "手机号码输入有误，请重新填写",
          type: "warning"
        });
        return;
      }

      if (!this.tryConsultParams.description) {
        ElMessage({
          message: "请输入咨询内容",
          type: "warning"
        });
        return;
      }

      this.$axios.post("/api/customerticket", {
        description: this.tryConsultParams.description,
        category: 1,
        name: this.tryConsultParams.name,
        phoneNumber: this.tryConsultParams.phoneNumber,
        AppId: this.appId
      }).then(function (res) {
        _this7.loading = false;

        if (res.code === 0) {
          ElMessage({
            message: res.message || "提交成功请耐心等待...",
            type: "success"
          });
          _this7.tryConsultParams.description = "";
          _this7.tryConsultParams.name = "";
          _this7.tryConsultParams.phoneNumber = "";
          _this7.tryConsultVisible = false;
        } else {
          ElMessage({
            message: res.message || "操作失败",
            type: "warning"
          });
        }
      });
    },
    //申请试用
    handelView: function handelView(data) {
      var _this8 = this;

      this.appId = data.gid;

      if (!data.address) {
        this.tryConsultVisible = true;
      } else {
        this.$axios.get("/api/trial-account/apply?type=" + data.type).then(function (res) {
          _this8.loading = false;

          if (res.code === 0) {
            // ElMessage({
            //   message: res.message || '提交成功请耐心等待...',
            //   type: 'success'
            // })
            _this8.viewData = res.data.data;
            _this8.viewvisible = true;
          } else {
            ElMessage({
              message: res.message || "操作失败",
              type: "warning"
            });
          }
        });
      } // this.viewData = data;
      // if(data.address != '' && data.address != null){
      //   this.viewvisible = true;
      // }

    },
    // 切割数组
    spliceArr: function spliceArr(arr, num) {
      var reArr = [];
      arr.map(function (item) {
        if (!reArr[reArr.length - 1] || reArr[reArr.length - 1].length === num) {
          // 新⾏添加
          reArr.push([]);
        }

        if (reArr[reArr.length - 1].length !== num) {
          // 长度不够则添加
          reArr[reArr.length - 1].push(item);
        }
      });
      return reArr;
    }
  }
};